export default function Banner() {
  return <div className='hidden md:flex w-full flex-nowrap mx-auto bg-gray-900'>
    <nav class="container mx-auto flex justify-between flex-wrap p-3 pl-4">
      <div class="mr-3 ">
        <span class="font-semibold text-3xl tracking-tight ">kmacquin</span>
      </div>
      <div class="block lg:hidden">
        <button class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div class="text-sm lg:flex-grow">
          <a
            href="#welcome"
            class="block mt-4 lg:inline-block lg:mt-0 navtext mr-4"
          >
            About
          </a>
          <a
            href="#projects"
            class="block mt-4 lg:inline-block lg:mt-0 navtext mr-4"
          >
            Projects
          </a>
          {/* <a
            href="#resume"
            class="block mt-4 lg:inline-block lg:mt-0 navtext mr-4"
          >
            Resume
          </a> */}
          <a
            href="https://kylemacquin.medium.com/"
            class="block mt-4 lg:inline-block lg:mt-0 navtext mr-4"
          >
            Blog
          </a>
        </div>
      </div>
    </nav>
  </div>
}
