
export default function ProjectCard(props) {
  return <div className="container mx-auto bg-gray-700 rounded-sm border border-black">
    <a href={props.projectLink}>
      <img src={props.imageLink} className='rounded-t-sm ' alt=""></img>
      <div className="container p-4">
        <p className="text-3xl font-bold mb-5">
          {props.projectName}
        </p>
        <p className="text-lg">
          {props.projectBody}
        </p>
      </div>
    </a>
  </div>
}