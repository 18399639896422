import logo from "./logo.svg";
import "./App.css";
import NavBar from './NavBar.js';
import Resume from './Resume.js';
import ProjectCard from './ProjectCard.js';

import aliensentimental from "./img/AlienSentimentalV2.png";
import atlanticgeorge from "./img/AtlanticGeorgeV2.png";
import kaggle from "./img/SnakeV2.png";
import tradeworldicon from "./img/TradeWorldV2.png";
import thesisimage from "./img/ThesisImageV2.png";
import portfoliopage from "./img/PortfolioPage.png";


function App() {
  var dataAS = {
    projectName: 'Alien Sentimental',
    projectBody: `Users search reddit by username or subreddit name and have
                            cards generated which display user content and their 
                            respective sentitment. Sentiment is aggregated and displayed in pie and bar charts.`,
    projectLink: "https://aliensentimental.kmacquin.online/",
    imageLink: aliensentimental
  }

  var dataAG = {
    projectName: 'Atlantic George',
    projectBody: `Squarespace website designed to give the client an online
presence with an objective to provide a clear layout of services
and funnel to contact the owner.`,
    projectLink: "https://www.agltd.ca/",
    imageLink: atlanticgeorge
  }

  var dataIS = {
    projectName: 'Intelligent Snake',
    projectBody: `Kaggle competition submission. This algorithm is designed to
    play the timeless game, Snake. Our team implemented machine learning methods, training on weak AI
and agents with the same policy.`,
    projectLink: "https://github.com/kmqu/Kaggle_Games",
    imageLink: kaggle
  }

  var dataTW = {
    projectName: 'Trade World',
    projectBody: `Oanda users may place orders traditionally via their proprietary tools.
    Trade World is being built to help make implementing complex algorithms easy.`,
    projectLink: "#welcome",
    imageLink: tradeworldicon
  }

  var dataUT = {
    projectName: 'Honours Thesis',
    projectBody: `Undergraduate report presenting an extension to the standard SIR model of 
infective disease spread. The model is extended to 
analyze the spatial dynamics of populations exhibiting hysteresis.`,
    projectLink: "https://thesis.kmacquin.online/",
    imageLink: thesisimage
  }

  var dataPW = {
    projectName: 'Portfolio Website',
    projectBody: `This very page! It's purpose is to showcase my work to date in a creative and
    engaging format. Tailwind CSS and React were used in tandem to produce the content you see here.`,
    projectLink: "http://kmacquin.online/",
    imageLink: portfoliopage
  }

  return (

    <div className="App p-4 md:p-0">
      
      <NavBar />
      <br></br>
      <div className="container grid grid-cols-1 mx-auto md:p-4">
        <div className="container " id="welcome">
          <p className="text-6xl font-bold mb-5 ">Hello!</p>
          <p className="text-4xl font-bold mb-5 nomarge">My name is Kyle MacQuin</p>
          <div className="container grid grid-cols-1 text-lg mt-2 md:grid-cols-3">
            <div className = "col-span-2">
            I currently work as a senior analyst responsible for
            procuring and processing financial data for use in our firm's various products. 
            While not at the office I enjoying
            learning new technologies and improving my 5K time. Please
            check out my work and professional history below. 
            You can reach me at kylemacquin@gmail.com, I'm always happy to answer any questions.
            </div>
          </div>
          <br></br>
        </div>
        

        <div className="container">
          <p className="text-4xl font-bold mb-5" id="projects">Projects</p>
          <hr></hr><br></br>
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
            <ProjectCard projectName={dataAS.projectName} projectBody={dataAS.projectBody} projectLink={dataAS.projectLink} imageLink={dataAS.imageLink} />
            <ProjectCard projectName={dataAG.projectName} projectBody={dataAG.projectBody} projectLink={dataAG.projectLink} imageLink={dataAG.imageLink} />
            <ProjectCard projectName={dataIS.projectName} projectBody={dataIS.projectBody} projectLink={dataIS.projectLink} imageLink={dataIS.imageLink} />
            <ProjectCard projectName={dataTW.projectName} projectBody={dataTW.projectBody} projectLink={dataTW.projectLink} imageLink={dataTW.imageLink} />
            <ProjectCard projectName={dataPW.projectName} projectBody={dataPW.projectBody} projectLink={dataPW.projectLink} imageLink={dataPW.imageLink} />
            <ProjectCard projectName={dataUT.projectName} projectBody={dataUT.projectBody} projectLink={dataUT.projectLink} imageLink={dataUT.imageLink} />
          
          </div>
        </div>
        {/* <div className="container">
          <br></br>
          <p className="text-4xl font-bold mb-5" id="resume">Resume</p>
          <hr class='pb-3'></hr>
          <div class="text-3xl  font-semibold  ">
          Employment History
        </div> 
        </div>*/}
      </div>
    </div>
  );
}

export default App;
